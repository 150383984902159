import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore'; // Pour récupérer, mettre à jour et supprimer l'énigme depuis Firestore
import { getStorage, ref, getDownloadURL, uploadBytes, uploadString } from 'firebase/storage'; // Pour gérer le stockage Firebase
import { db } from '../../firebase/firebaseConfig';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify'; // Pour les notifications
import 'react-toastify/dist/ReactToastify.css';
import whiteLogo from '../../assets/white.png'; // Import du logo blanc
import CryptoJS from 'crypto-js';
import jsQR from "jsqr";

const EditRiddle = () => {
    const { huntId, riddleId } = useParams(); // Récupérer les paramètres de l'URL
    const [riddle, setRiddle] = useState(null);
    const [huntName, setHuntName] = useState(''); // État pour stocker le nom de la chasse
    const [imagePreview, setImagePreview] = useState(null);
    const [audioPreview, setAudioPreview] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState(null); // URL du QR code
    const navigate = useNavigate();
    const storage = getStorage();
    const [randomKey, setRandomKey] = useState(''); // Stocker le randomKey
    const [iv, setIv] = useState(''); // Stocker l'IV
    const [latitude, setLatitude] = useState(''); // Champs pour la latitude
    const [longitude, setLongitude] = useState(''); 

    const normalizeHuntName = (huntName) => huntName.replace(/[^a-zA-Z0-9]/g, ''); // Normaliser le nom de la chasse

    // Récupérer les détails de la chasse (nom)
    useEffect(() => {
        const fetchHuntName = async () => {
            try {
                const huntRef = doc(db, 'hunt', huntId);
                const huntSnap = await getDoc(huntRef);
                if (huntSnap.exists()) {
                    setHuntName(huntSnap.data().name); // Stocker le nom de la chasse
                }
            } catch (error) {
                toast.error('Erreur lors de la récupération de la chasse.');
            }
        };
        fetchHuntName();
    }, [huntId]);

    // Récupérer les détails de l'énigme
    useEffect(() => {
        const fetchRiddle = async () => {
            try {
                const riddleRef = doc(db, 'riddle', riddleId);
                const riddleSnap = await getDoc(riddleRef);
                if (riddleSnap.exists()) {
                    setRiddle(riddleSnap.data());
                    setImagePreview(riddleSnap.data().picture); // Prévisualisation de l'image principale
                    setAudioPreview(riddleSnap.data().audio); // Prévisualisation de l'audio
                }
            } catch (error) {
                toast.error('Erreur lors de la récupération de l\'énigme.');
            }
        };

        fetchRiddle();
    }, [riddleId]);

    // Générer et récupérer le QR code depuis Firebase Storage
    useEffect(() => {
        const fetchQrCode = async () => {
            if (huntName && riddle) {
                const normalizedHuntName = normalizeHuntName(huntName);
                const qrCodeRef = ref(storage, `riddle/${normalizedHuntName}/qrcodes/enigme${riddle.number}`);
                try {
                    const url = await getDownloadURL(qrCodeRef);
                    setQrCodeUrl(url);                       
                    analyzeQrCodeFromUrl(url)
                } catch (error) {
                    toast.error('Erreur lors de la récupération du QR code.');
                }
            }
        };
        fetchQrCode();
    }, [huntName, riddle, storage]);

    const analyzeQrCodeFromUrl = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const imageBitmap = await createImageBitmap(blob);

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = imageBitmap.width;
            canvas.height = imageBitmap.height;
            context.drawImage(imageBitmap, 0, 0);

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const qrCode = jsQR(imageData.data, canvas.width, canvas.height);

            if (qrCode) {
                const qrUrl = qrCode.data;                
                extractRandomKeyAndIv(qrUrl);
            } else {
                toast.error('Impossible d\'analyser le QR code.');
            }
        } catch (error) {
            toast.error('Erreur lors de l\'analyse du QR code.');
            console.error(error);
        }
    };    

    // Extraire randomKey et IV à partir de l'URL
    const extractRandomKeyAndIv = (url) => {
        try {
            const parts = new URL(url).pathname.split('/');

            if (parts.length >= 3) {
                const randomKeyFromUrl = parts[1];
                const ivFromUrl = parts[2];

                setRandomKey(randomKeyFromUrl);
                setIv(ivFromUrl);

                // Une fois randomKey et iv récupérés, décryptons les coordonnées
                if (riddle && riddle.coordinates) {
                    decryptCoordinates(riddle.coordinates, randomKeyFromUrl, ivFromUrl);
                }
            } else {
                toast.error('Format de l\'URL invalide pour extraire randomKey et iv.');
            }
        } catch (error) {
            toast.error('Erreur lors de l\'extraction de randomKey et iv.');
        }
    };

    // Méthode pour décrypter les coordonnées
    const decryptCoordinates = (encryptedCoordinates, randomKey, iv) => {
        try {
            
            const decrypted = CryptoJS.AES.decrypt(encryptedCoordinates,CryptoJS.enc.Hex.parse(randomKey) , { iv: CryptoJS.enc.Hex.parse(iv)  });
            const decryptedText = decrypted.toString(CryptoJS.enc.Utf8); // Les coordonnées décryptées en texte clair           
            // Les coordonnées sont sous la forme "latitude, longitude", donc on les divise
            const [lat, lng] = decryptedText.split(',').map(coord => coord.trim());

            // Populer les champs latitude et longitude
            setLatitude(lat);
            setLongitude(lng);

        } catch (error) {
            toast.error('Erreur lors du décryptage des coordonnées.');
        }
    };

    // Gérer les modifications dans les champs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'latitude') {
            setLatitude(value);
        } else if (name === 'longitude') {
            setLongitude(value);
        } else {
            setRiddle({ ...riddle, [name]: value });
        }
    };
    

    // Gérer les fichiers audio et image
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (name === 'audio') {
            setRiddle({ ...riddle, audio: file });
            setAudioPreview(URL.createObjectURL(file));
        } else if (name === 'picture') {
            setRiddle({ ...riddle, picture: file });
            const reader = new FileReader();
            reader.onloadend = () => setImagePreview(reader.result);
            reader.readAsDataURL(file);
        }
    };

    // Supprimer une image secondaire
    // Supprimer une image secondaire
    const handleRemoveSecondaryImage = (index, e) => {
        e.preventDefault(); // Empêche la page de se recharger
        const newPicturesRiddle = [...riddle.picturesRiddle];
        newPicturesRiddle.splice(index, 1); // Supprime l'image à l'index donné
        setRiddle({ ...riddle, picturesRiddle: newPicturesRiddle });
    };

    const isValidCoordinate = (value) => {
        const coordinatePattern = /^-?\d+(\.\d+)?$/; // Autorise les nombres avec ou sans décimale, avec un signe négatif optionnel
        return coordinatePattern.test(value);
    };


    const handleSecondaryImagesChange = (e) => {
        const files = e.target.files;

        const totalImages = riddle.picturesRiddle.length + files.length; // Calculer le nombre total d'images
    
        if (totalImages > 6) {
            toast.error("Le nombre maximum d'images secondaires est de 6.", { position: "top-right", autoClose: 3000 });
            return; // Arrêter la fonction si la limite est dépassée
        }
        
        const newPicturesRiddle = [...riddle.picturesRiddle, ...Array.from(files)]; // Ajouter les nouvelles images à la liste existante
        setRiddle({ ...riddle, picturesRiddle: newPicturesRiddle });
    };

   
    // Gérer la sauvegarde des modifications de l'énigme
const handleSave = async () => {
    toast.info("Mise à jour de l'éngime...");
    try {
        const riddleRef = doc(db, 'riddle', riddleId);
        const normalizedHuntName = normalizeHuntName(huntName);

        // 1. Gérer l'upload de l'image principale si elle a été modifiée
        if (typeof riddle.picture === 'object') {
            const pictureRef = ref(storage, `riddle/${normalizedHuntName}/pictures/${riddle.picture.name}`);
            await uploadBytes(pictureRef, riddle.picture);
            const pictureUrl = await getDownloadURL(pictureRef);
            riddle.picture = pictureUrl; // Met à jour l'URL de l'image principale
        }

        if (!isValidCoordinate(latitude) || !isValidCoordinate(longitude)) {
            toast.error("Veuillez entrer des coordonnées valides.");
            return;
        }

        if (latitude && longitude) {
            // Si la latitude et la longitude ne sont pas vides, combiner et chiffrer
            const coordinates = `${latitude}, ${longitude}`.trim(); 
            var encryptedCoordinates = CryptoJS.AES.encrypt(coordinates, CryptoJS.enc.Hex.parse(randomKey), { iv: CryptoJS.enc.Hex.parse(iv) });
        }

        // 2. Gérer l'upload de l'audio s'il a été modifié
        if (typeof riddle.audio === 'object') {            
            const audioRef = ref(storage, `riddle/${normalizedHuntName}/audios/enigme${riddle.number}`);
            await uploadBytes(audioRef, riddle.audio);
            const audioUrl = await getDownloadURL(audioRef);
            riddle.audio = audioUrl; // Met à jour l'URL de l'audio
        } else if (!riddle.audio) {
            // Si aucun audio n'est fourni, on sauvegarde une chaîne vide
            riddle.audio = "";
        }

        // 3. Gérer l'upload des nouvelles images secondaires
        const updatedSecondaryImages = await Promise.all(
            riddle.picturesRiddle.map(async (file) => {
                if (typeof file === 'object') { // Si le fichier est un objet (nouvelle image)
                    const pictureRef = ref(storage, `riddle/${normalizedHuntName}/pictures/${file.name}`);
                    await uploadBytes(pictureRef, file);
                    return await getDownloadURL(pictureRef); // Retourne l'URL de l'image uploadée
                } else {
                    return file; // Si l'image est déjà en ligne, ne pas changer
                }
            })
        );
        
        // Mettre à jour la liste des images secondaires avec les nouvelles URLs
        riddle.picturesRiddle = updatedSecondaryImages;

        // 4. Sauvegarder les modifications dans Firestore
        await updateDoc(riddleRef, {
            description: riddle.description,
            picture: riddle.picture,
            audio: riddle.audio,
            picturesRiddle: riddle.picturesRiddle,
            coordinates: encryptedCoordinates.toString(),
        });

        toast.success('Énigme mise à jour avec succès !');
        
    } catch (error) {
        toast.error('Erreur lors de la sauvegarde de l\'énigme.');
        console.error(error);
    }
};

    

    // Gérer la suppression de l'énigme
    // Gérer la suppression de l'énigme et décrémenter nbRiddle dans la chasse
const handleDelete = async () => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette énigme ?');
    if (confirmDelete) {
        try {
            const riddleRef = doc(db, 'riddle', riddleId);
            const huntRef = doc(db, 'hunt', huntId);

            // Supprimer l'énigme de Firestore
            await deleteDoc(riddleRef);

            // Décrémenter nbRiddle dans la chasse
            const huntSnap = await getDoc(huntRef);
            if (huntSnap.exists()) {
                const currentNbRiddle = huntSnap.data().nbRiddle;
                await updateDoc(huntRef, { nbRiddle: currentNbRiddle - 1 });
            }

            toast.success('Énigme supprimée avec succès.');
            navigate(`/riddles/${huntId}`);
        } catch (error) {
            toast.error('Erreur lors de la suppression de l\'énigme.');
        }
    }
};


    if (!riddle) {
        return <LoadingMessage>Chargement de l'énigme...</LoadingMessage>;
    }

    return (
        <Container>
            <ToastContainer />
            
            <Sidebar>
                <Logo src={whiteLogo} alt="App Logo" />
                <SidebarItem onClick={() => navigate('/hunts')}>Chasses</SidebarItem>
                <SidebarItem onClick={() => navigate('/riddles')}>Enigmes</SidebarItem>
            </Sidebar>

            <Content>
                <HeaderWrapper>
                    <Title>Éditer - Énigme {riddle.number} - {huntName} </Title>
                    <ButtonGroup>
                        <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                        <SubmitButton onClick={handleSave}>Sauvegarder</SubmitButton>
                    </ButtonGroup>
                </HeaderWrapper>

                <ContentBox>
                    <Form>
                        <InputWrapper>
                            <label>Audio :</label>
                            <input type="file" name="audio" accept="audio/*" onChange={handleFileChange} />
                            {audioPreview && <AudioPlayer controls src={audioPreview} />}
                        </InputWrapper>

                        <InputWrapper>
                            <label>Description :</label>
                            <textarea name="description" value={riddle.description} onChange={handleInputChange} required />
                        </InputWrapper>

                        <InputWrapper>
                            <label>Image Principale :</label>
                            <input type="file" name="picture" accept="image/*" onChange={handleFileChange} />
                            {imagePreview && <ImagePreview src={imagePreview} alt="Pas d'image principale" />}
                        </InputWrapper>

                        <InputWrapper>
                            <label>Ajouter des Images Secondaires (6max):</label>
                            <input type="file" accept="image/*" multiple onChange={handleSecondaryImagesChange} />
                                {riddle.picturesRiddle && riddle.picturesRiddle.length > 0 && (
                                    riddle.picturesRiddle.map((img, index) => (
                                    <ImagePreviewWrapper key={index}>
                                        <ImagePreview src={typeof img === 'string' ? img : URL.createObjectURL(img)} alt={`Image secondaire ${index + 1}`} />
                                        <RemoveButton onClick={(e) => handleRemoveSecondaryImage(index, e)}>Supprimer</RemoveButton>

                                    </ImagePreviewWrapper>
                                    ))
                                )}
                        </InputWrapper>
                        <FlexContainer>
                        <InputWrapper>
                            <label>Latitude :</label>
                                <input
                                    type="number"
                                    name="latitude"
                                    placeholder="Exemple: 48.13065361205093"
                                    value={latitude}
                                    onChange={handleInputChange}
                                    step="any"
                                    required                                    
                                />
                        </InputWrapper>

                        <InputWrapper>
                            <label>Longitude :</label>
                            <input
                                type="number"
                                name="longitude"
                                placeholder="Exemple: -1.6958905470621606"
                                value={longitude}
                                onChange={handleInputChange}
                                step="any"
                                required
                            />
                        </InputWrapper>
                        </FlexContainer>


                        {qrCodeUrl && (
                            <InputWrapper>
                                <label>QR Code :</label>
                                <ImagePreview src={qrCodeUrl} alt="QR Code" />
                                <DownloadButton href={qrCodeUrl} download={`enigme${riddle.number}_qrcode.png`}>Télécharger QR Code</DownloadButton>
                            </InputWrapper>
                        )}
                    </Form>
                </ContentBox>
            </Content>
        </Container>
    );
};
// Styled Components


const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Espacement entre les champs */
    margin-bottom: 1.5rem; /* Espace entre les champs et les éléments suivants */
`;

const Container = styled.div`
    display: flex;
    height: 100vh;
    background-color: #1f2b3a; /* Fond bleu sombre */
`;

const Sidebar = styled.div`
    width: 250px;
    background-color: #1f2b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: white;
`;

const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-bottom: 2rem;
`;

const SidebarItem = styled.div`
    font-size: 1.25rem;
    margin: 1rem 0;
    cursor: pointer;
    color: ${({ active }) => (active ? '#19a544' : 'white')}; /* Vert quand actif */
    &:hover {
        background-color: #34495e;
        width: 100%;
        text-align: center;
        padding: 0.5rem 0;
        color: #19a544; /* Vert au survol */
    }
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between; /* Titre à gauche, bouton à droite */
    align-items: center;
    margin-bottom: 2rem;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 800;
    color: white; /* Couleur blanche pour contraster avec le fond */
`;

const ContentBox = styled.div`
    background-color: white;
    flex: 1;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 100%; /* Assurer que la boîte ne dépasse pas */
    overflow-y: auto; /* Permettre le scroll si nécessaire */
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1; /* Chaque InputWrapper prendra une largeur égale */
    label {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }
    input {
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        width: 100%; /* S'assurer que l'input prend toute la largeur disponible */
    }
`;

const AudioPreview = styled.audio`
    width: 100%;
    margin-top: 0.5rem;
`;

const ImagePreview = styled.img`
    width: 200px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 0.5rem;
`;

const SubmitButton = styled.button`
    padding: 1rem 3rem; /* Augmentation du padding pour un bouton plus grand */
    background-color: #19a544;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem; /* Augmentation de la taille de la police */
    cursor: pointer;
    &:hover {
        background-color: #16a085;
    }
`;

const LoadingMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    font-weight: bold;
`;

// Groupe de boutons (Sauvegarder et Supprimer)
const ButtonGroup = styled.div`
    display: flex;
    gap: 1rem; /* Espace entre les boutons */
`;

// Bouton de suppression
const DeleteButton = styled.button`
    padding: 1rem 2rem;
    background-color: #e74c3c; /* Rouge pour indiquer une action dangereuse */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.25rem;
    cursor: pointer;

    &:hover {
        background-color: #c0392b;
    }
`;

// Player audio
const AudioPlayer = styled.audio`
    width: 100%;
    margin-top: 10px;
`;

// Wrapper pour les images secondaires
const ImagePreviewWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; /* Espace entre l'image et le bouton de suppression */
    margin-top: 1rem;

    img {
        width: 150px;
        height: auto;
        border-radius: 5px;
        border: 1px solid #ccc;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
`;

// Bouton pour supprimer une image secondaire
const RemoveButton = styled.button`
    padding: 0.5rem 1rem;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.875rem;
    cursor: pointer;

    &:hover {
        background-color: #c0392b;
    }
`;

// Bouton pour télécharger le QR code
const DownloadButton = styled.a`
    padding: 0.75rem 2rem;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    display: inline-block;
    margin-top: 1rem;
    cursor: pointer;

    &:hover {
        background-color: #2980b9;
    }
`;


export default EditRiddle;
