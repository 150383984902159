import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Firebase Firestore imports
import { db } from '../../firebase/firebaseConfig'; // Import Firebase config
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { deleteDoc } from 'firebase/firestore'; // Import pour la suppression
import { ToastContainer, toast } from 'react-toastify'; // Pour les notifications
import 'react-toastify/dist/ReactToastify.css'; // Import des styles de react-toastify
import styled from 'styled-components';
import whiteLogo from '../../assets/white.png'; // Import du logo blanc

const EditHunt = () => {
    const { huntId } = useParams(); // Récupérer l'ID de la chasse à partir des paramètres de l'URL
    const [hunt, setHunt] = useState(null);
    const [loading, setLoading] = useState(true); // Gestion du chargement
    const [imagePreview, setImagePreview] = useState(''); // URL de prévisualisation de l'image actuelle
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        code: '',
        location: '',
        nbPlayer: 1,
        limited: true,
        price: 1,
        reward: 1,
        deployment: false,
        picture: null, // Gestion du fichier image
        startingDate: '', // Ajout de la date de début
        endingDate: '', // Ajout de la date de fin
    });

    const navigate = useNavigate();
    const location = useLocation();
    const [codeDigits, setCodeDigits] = useState(Array(6).fill('')); // Initialiser avec 6 champs vides


    // Charger les détails de la chasse
    useEffect(() => {
        const fetchHunt = async () => {
            const huntRef = doc(db, 'hunt', huntId);
            const huntSnap = await getDoc(huntRef);
            if (huntSnap.exists()) {
                const huntData = huntSnap.data();
                setHunt(huntData);
                setFormData({
                    ...huntData,
                    startingDate: huntData.startingDate?.toDate().toISOString().split('T')[0] || '',
                    endingDate: huntData.endingDate?.toDate().toISOString().split('T')[0] || '',
                });
                setImagePreview(huntData.picture);
                if (huntData.code && huntData.code.length === 6) {
                    setCodeDigits(huntData.code.split(''));
                }
            } else {
                toast.error('Aucune chasse trouvée.', { position: "top-right", autoClose: 3000 });
            }
            setLoading(false);
        };
    
        fetchHunt();
    }, [huntId]);

    
    const handleCodeChange = (e, index) => {
        const { value } = e.target;
        
        // Assurez-vous que seuls les chiffres sont acceptés
        if (/^\d$/.test(value) || value === '') {
            const newCodeDigits = [...codeDigits];
            newCodeDigits[index] = value;
            setCodeDigits(newCodeDigits);
        }
    };

    // Gestion de la modification des inputs
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        // Vérifier si le champ est de type "number" et caster en nombre
        const newValue = type === 'number' ? Number(value) : value;
    
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : newValue,
        });
    };

    // Gestion de la modification de l'image
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            picture: file,
        });

        // Prévisualisation de l'image uploadée
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setImagePreview(fileReader.result); // Mettre à jour la prévisualisation
        };
        fileReader.readAsDataURL(file);
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette chasse ?');
        
        if (confirmDelete) {
            try {
                // Supprimer la chasse de Firestore
                const huntRef = doc(db, 'hunt', huntId);
                await deleteDoc(huntRef);
    
                toast.success('Chasse supprimée avec succès !', { position: "top-right", autoClose: 3000 });
                navigate('/hunts'); // Rediriger vers la liste des chasses après suppression
            } catch (error) {
                console.error('Erreur lors de la suppression de la chasse', error);
                toast.error('Erreur lors de la suppression de la chasse.', { position: "top-right", autoClose: 3000 });
            }
        }
    };
    

    // Sauvegarder les modifications
    const handleSave = async () => {
        toast.info("Mise à jour de la chasse...");
        setLoading(true); // Activer le chargement
        const storage = getStorage();
        let imageUrl = formData.picture;

        const code = codeDigits.join('');

        try {
            if (formData.picture instanceof File) {
                const storageRef = ref(storage, `hunt/${formData.picture.name}`);
                const snapshot = await uploadBytes(storageRef, formData.picture);
                imageUrl = await getDownloadURL(snapshot.ref); // Obtenir l'URL de l'image téléchargée
            }

            const huntRef = doc(db, 'hunt', huntId);
            await updateDoc(huntRef, {
                ...formData,
                code,
                picture: imageUrl, // Mettre à jour l'URL de l'image
                startingDate: formData.startingDate ? new Date(formData.startingDate) : null, // Convertir la chaîne en date
                endingDate: formData.endingDate ? new Date(formData.endingDate) : null, // Convertir la chaîne en date
            });

            toast.success('Chasse mise à jour avec succès !', { position: "top-right", autoClose: 3000 });
            setLoading(false);            
           
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la chasse', error);
            toast.error('Erreur lors de la mise à jour de la chasse.', { position: "top-right", autoClose: 3000 });
            setLoading(false); // Désactiver le chargement en cas d'erreur
        }
    };

    

    return (
        <Container>
            {/* Notifications */}
            <ToastContainer />

            <Sidebar>
                <Logo src={whiteLogo} alt="App Logo" />
                <SidebarItem 
                    onClick={() => navigate('/hunts')} 
                    active={location.pathname.includes('/hunts')} // Vérifie si l'URL inclut '/hunts'
                >
                    Chasses
                </SidebarItem>
                <SidebarItem 
                    onClick={() => navigate('/riddles')} 
                    active={location.pathname.includes('/riddles')} // Vérifie si l'URL inclut '/riddles'
                >
                    Enigmes
                </SidebarItem>
            </Sidebar>

            <Content>
            <HeaderWrapper>
                <Title>Éditer la Chasse - {formData.name}</Title>
                    <div>
                        <DeleteButton onClick={handleDelete} disabled={loading}>
                        {'Delete'}
                        </DeleteButton>
                        <SaveButton onClick={handleSave} disabled={loading}>
                            {loading ? 'Sauvegarde...' : 'Sauvegarder'}
                        </SaveButton>
                    </div>
            </HeaderWrapper>

                <ContentBox>
                    <Form onSubmit={handleSave}>
                        <FormWrapper>
                            <LeftColumn>
                                <InputWrapper>
                                    <label>Nom :</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Description :</label>
                                    <textarea
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Code :</label>
                                        <CodeInputWrapper>
                                        {codeDigits.map((digit, index) => (
                                            <CodeInput
                                                key={index}
                                                 type="text"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) => handleCodeChange(e, index)}
                                            />
                                         ))}
                                        </CodeInputWrapper>
                                </InputWrapper>


                                <InputWrapper>
                                    <label>Lieu :</label>
                                    <input
                                        type="text"
                                        name="location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Nombre de Joueurs :</label>
                                    <input
                                        type="number"
                                        name="nbPlayer"
                                        value={formData.nbPlayer}
                                        min="1"
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <SwitchWrapper>
                                    <label>Limitée :</label>
                                    <Switch>
                                        <input
                                            type="checkbox"
                                            name="limited"
                                            checked={formData.limited}
                                            onChange={handleInputChange}
                                        />
                                        <Slider />
                                    </Switch>
                                </SwitchWrapper>

                                <InputWrapper>
                                    <label>Prix :</label>
                                    <input
                                        type="number"
                                        name="price"
                                        value={formData.price}
                                        min="1"
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Récompense :</label>
                                    <input
                                        type="number"
                                        name="reward"
                                        value={formData.reward}
                                        min="1"
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Image :</label>
                                    <input type="file" onChange={handleFileChange} />
                                    {/* Afficher une prévisualisation de l'image actuelle */}
                                    {imagePreview && <ImagePreview src={imagePreview} alt="Aperçu de l'image" />}
                                </InputWrapper>
                            </LeftColumn>

                            <RightColumn>
                                <SwitchWrapper>
                                    <label>Déployée :</label>
                                    <Switch>
                                        <input
                                            type="checkbox"
                                            name="deployment"
                                            checked={formData.deployment}
                                            onChange={handleInputChange}
                                        />
                                        <Slider />
                                    </Switch>
                                </SwitchWrapper>
                                <InputWrapper>
                                    <label>Date de début :</label>
                                        <input
                                            type="date"
                                            name="startingDate"
                                            value={formData.startingDate}
                                            onChange={handleInputChange}
                                            required
                                        />
                                </InputWrapper>

                                <InputWrapper>
                                 <label>Date de fin :</label>
                                     <input
                                        type="date"
                                        name="endingDate"
                                        value={formData.endingDate}
                                        onChange={handleInputChange}
                                        required
                                    />
                            </InputWrapper>
                            </RightColumn>
                        </FormWrapper>
                    </Form>
                </ContentBox>
            </Content>
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    display: flex;
    height: 100vh;
    background-color: #1f2b3a; /* Background color for container */
`;

const Sidebar = styled.div`
    width: 250px;
    background-color: #1f2b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: white;
`;

const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-bottom: 2rem;
`;

const SidebarItem = styled.div`
    font-size: 1.25rem;
    margin: 1rem 0;
    cursor: pointer;
    color: ${({ active }) => (active ? '#19a544' : 'white')}; /* Green when active, white otherwise */
    &:hover {
        background-color: #34495e;
        width: 100%;
        text-align: center;
        padding: 0.5rem 0;
        color: #19a544; /* Green hover */
    }
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between; /* Aligner le bouton à droite */
    align-items: center;
    margin-bottom: 2rem;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 800;
    color: white;
`;

const ContentBox = styled.div`
    background-color: white;
    flex: 1;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 100%; /* Ensure the box doesn't overflow */
    overflow-y: auto; /* Make the box scrollable */
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const FormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    flex: 1;
    overflow-y: auto; /* Scroll if content overflows */
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 70%;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 25%;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }
    input, textarea {
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        width: 100%;
    }
    textarea {
        resize: vertical;
    }
`;

const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;

    &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + & {
        background-color: #4CAF50;
    }

    input:checked + &::before {
        transform: translateX(26px);
    }
`;

const SaveButton = styled.button`
    padding: 1rem 3rem;
    background-color: #19a544;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
        background-color: #16a085;
    }
`;

const LoadingMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    font-weight: bold;
`;

const ImagePreview = styled.img`
    margin-top: 10px;
    width: 200px; /* Largeur de l'aperçu de l'image */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;


const CodeInputWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

const CodeInput = styled.input`
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
`;
const DeleteButton = styled.button`
    padding: 1rem;
    margin-right: 1rem;
    background-color: #e74c3c; /* Rouge pour le bouton suppression */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
        background-color: #c0392b;
    }
    i {
        margin-right: 0.5rem;
    }
`;




export default EditHunt;
