// src/components/Footer.js

import React from 'react';
import styled from "styled-components";
import Panel from "../../components/Panel";
import Column from "../../components/Column";
import Row from "../../components/Row";
import { useNavigate, useLocation } from "react-router-dom";
import { faDiscord, faInstagram, faTelegram, faTwitter, faTiktok, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "../../components/Icon";

export const FooterListItem = styled.li`
    list-style: none;
    transition: all 0.2s ease-in-out;
    color: #FFF;

    &:hover {
        cursor: pointer;
        color: #19a544;
    }
`;

export const FooterList = styled.ul`
    display: flex;
    align-content: center;
    gap: 2rem;
    padding: 0;
    margin: 0;
`;

const FooterWrapper = styled(Row)`
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 2.5rem 3.75rem;

    @media (max-width: ${({theme}) => theme.screen.large}) {
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
`;

const FooterIcon = styled(Icon)`
    font-size: 28px;
    color: #FFF;

    &:hover {
        color: #19a544;
    }

    @media (max-width: ${({theme}) => theme.screen.medium}) {
        font-size: 24px;
    }

    @media (max-width: ${({theme}) => theme.screen.small}) {
        font-size: 20px;
    }
`;

const Divider = styled.div`
    width: 100%;
    border-bottom: 2px solid #1F2B3A;
    margin-top: 2rem;
`;

const Footer = () => {
    
    const location = useLocation();

    // Vérifie si la route actuelle correspond aux pages où le footer ne doit pas apparaître
    const hideFooter = ["/login"].includes(location.pathname) || location.pathname.startsWith("/riddles") || location.pathname.startsWith("/hunts");

    // Si la route est dans la liste des pages à exclure, ne pas afficher le Footer
    if (hideFooter) return null;

    return(
        <Panel style={{ height: "75vh", backgroundColor: "#000" }}>
            <FooterWrapper>
                <Column style={{ alignContent: "center", alignItems: "center"}}>
                    {/* Nouveaux liens ajoutés */}
                    <FooterList>
                        <a href="https://www.xplore-app.com/cgu-cgv" target="_blank" rel="noopener noreferrer">
                            <FooterListItem>
                                <h6>CGU / CGV</h6>
                            </FooterListItem>
                        </a>
                        <a href="https://www.xplore-app.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                            <FooterListItem>
                                <h6>Privacy Policy</h6>
                            </FooterListItem>
                        </a>
                    </FooterList>
                    <Divider />
                    {/* Icônes des réseaux sociaux déplacées ici */}
                    <Row style={{ gap: "2rem", alignItems: "center", justifyContent: "center", marginTop: "2rem"}}>
                        <a href="https://discord.gg/B4jEZbAWbW" target="_blank" rel="noreferrer">
                            <FooterIcon icon={faDiscord} />
                        </a>
                        <a href="https://www.instagram.com/xplore.app.project/" target="_blank" rel="noreferrer">
                            <FooterIcon icon={faInstagram} />
                        </a>
                        <a href="https://twitter.com/app_xplore" target="_blank" rel="noreferrer">
                            <FooterIcon icon={faTwitter} />
                        </a>
                        <a href="https://t.me/XploreApp" target="_blank" rel="noreferrer">
                            <FooterIcon icon={faTelegram} />
                        </a>
                        <a href="https://www.facebook.com/Xplore-103664729071310" target="_blank" rel="noreferrer">
                            <FooterIcon icon={faFacebookF} />
                        </a>
                        <a href="https://www.tiktok.com/@xplore_app" target="_blank" rel="noreferrer">
                            <FooterIcon icon={faTiktok} />
                        </a>
                    </Row>
                </Column>
            </FooterWrapper>
        </Panel>
    );
}

export default Footer;
