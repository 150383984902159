import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom'; // Import pour récupérer l'ID de la chasse
import { collection, doc, getDoc, query, where, getDocs } from 'firebase/firestore'; // Firebase Firestore imports
import { db } from '../../firebase/firebaseConfig'; // Import Firebase config
import whiteLogo from '../../assets/white.png'; // Import du logo blanc

const Riddles = () => {
    const [riddles, setRiddles] = useState([]);
    const [huntName, setHuntName] = useState(""); // Pour stocker le nom de la chasse
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { huntId } = useParams(); // Récupérer l'ID de la chasse à partir des paramètres de l'URL

    // Récupérer les détails de la chasse (nom) et les riddles associés
    useEffect(() => {
        const fetchHuntAndRiddles = async () => {
            try {
                // Récupérer le nom de la chasse à partir de l'ID
                const huntDocRef = doc(db, 'hunt', huntId);
                const huntDoc = await getDoc(huntDocRef);

                if (huntDoc.exists()) {
                    const huntData = huntDoc.data();
                    setHuntName(huntData.name); // Stocke le nom de la chasse
                } else {
                    console.log("Aucune chasse trouvée pour cet ID");
                }

                // Récupérer les riddles associés à cette chasse
                const riddleQuery = query(collection(db, 'riddle'), where('huntId', '==', huntId));
                const riddleSnapshot = await getDocs(riddleQuery);
                const riddlesArray = [];

                riddleSnapshot.forEach((doc) => {
                    riddlesArray.push({ id: doc.id, ...doc.data() });
                });

                setRiddles(riddlesArray);
                setLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des énigmes ou de la chasse :", error);
                setLoading(false);
            }
        };

        fetchHuntAndRiddles();
    }, [huntId]);

    if (loading) {
        return <LoadingMessage>Chargement des énigmes...</LoadingMessage>;
    }

    return (
        <Container>
            <Sidebar>
                <Logo src={whiteLogo} alt="App Logo" />
                <SidebarItem onClick={() => navigate('/hunts')} active={window.location.pathname.includes('/hunts')}>
                    Chasses
                </SidebarItem>
                <SidebarItem onClick={() => navigate('/riddles')} active={window.location.pathname.includes('/riddles')}>
                    Enigmes
                </SidebarItem>
            </Sidebar>

            <Content>
                <HeaderWrapper>
                    <Title>Enigmes de la chasse - {huntName}</Title> {/* Afficher le nom de la chasse */}
                </HeaderWrapper>

                <ContentBox>
                    <RiddleGrid>
                        {/* Ajouter le bouton "+" pour créer une nouvelle énigme */}
                        <RiddleBox onClick={() => navigate(`/riddles/${huntId}/newriddle`)}>
                        <PlusIconWrapper>
                            <FontAwesomeIcon icon={faPlus} size="3x" color="white" />
                        </PlusIconWrapper>
                        </RiddleBox>

                        {/* Affichage des énigmes */}
                        {riddles.length > 0 ? (
                            riddles.map((riddle) => (
                                <RiddleBox 
                                    key={riddle.id} 
                                    onClick={() => navigate(`/riddles/${huntId}/${riddle.id}/edit`)} 
                                    backgroundImage={riddle.picture} // Utilisation de l'image de fond
                                >
                                    <span>Enigme {riddle.number}</span> {/* Affiche le numéro de l'énigme */}
                                </RiddleBox>
                            ))
                        ) : (
                            <NoResults>Aucune énigme trouvée</NoResults>
                        )}
                    </RiddleGrid>
                </ContentBox>
            </Content>
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    display: flex;
    height: 100vh;
    background-color: #1f2b3a; /* Fond bleu sombre */
`;

const Sidebar = styled.div`
    width: 250px;
    background-color: #1f2b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: white;
`;

const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-bottom: 2rem;
`;

const SidebarItem = styled.div`
    font-size: 1.25rem;
    margin: 1rem 0;
    cursor: pointer;
    color: ${({ active }) => (active ? '#19a544' : 'white')}; /* Vert quand actif */
    &:hover {
        background-color: #34495e;
        width: 100%;
        text-align: center;
        padding: 0.5rem 0;
        color: #19a544;
    }
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: flex-start; /* Aligner à gauche */
    margin-bottom: 2rem;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 800;
    color: white;
`;

const ContentBox = styled.div`
    background-color: white;
    flex: 1;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 100%; /* Assurer que la boîte ne dépasse pas */
    overflow-y: auto; /* Permettre le scroll si nécessaire */
`;

const RiddleGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 énigmes par ligne */
    grid-gap: 1.5rem;
`;

const RiddleBox = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecf0f1;
    background-image: ${({ backgroundImage }) => backgroundImage ? `url(${backgroundImage})` : 'none'};
    background-size: cover;
    background-position: center;
    height: 200px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;

    /* Overlay sombre */
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5); /* Assombrir l'image de fond */
        z-index: 1; /* Assurez-vous que l'overlay est au-dessus de l'image */
    }

    /* Zoom sur le texte lors du survol de la boîte */
    &:hover span {
        transform: scale(1.1); /* Zoom sur le texte au survol */
    }

    span {
        position: relative;
        z-index: 2;
        color: white;
        font-size: 1.5rem;
        font-weight: bold; /* Texte en gras */
        text-align: center;
        transition: transform 0.3s ease-in-out; /* Transition fluide pour le zoom */
    }
`;

const PlusIconWrapper = styled.div`
    position: relative;
    z-index: 2; /* Place l'icône au-dessus de l'overlay pour ne pas être assombrie */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Couleur blanche pour l'icône + */
    transition: transform 0.3s ease-in-out; /* Smooth zoom transition */

    /* Zoom effect on hover for the icon */
    ${RiddleBox}:hover & {
        transform: scale(1.1); /* Zoom l'icône + au survol */
    }
`;

const NoResults = styled.div`
    grid-column: span 5;
    text-align: center;
    font-size: 1.25rem;
    color: red;
`;

const LoadingMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.5rem;
    font-weight: bold;
`;

export default Riddles;
