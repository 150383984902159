import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import Panel from '../../components/Panel';
import Column from '../../components/Column';
import Row from '../../components/Row';
import screenshot from '../../assets/app.png';
import logo from '../../assets/white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BackgroundImage } from '../../components/BackgroundImage';
import { faGooglePlay, faApple } from '@fortawesome/free-brands-svg-icons';

// Composant stylé pour le texte surligné avec italique
const HighlightedText = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-style: italic;
`;

// Composant stylé pour le logo
const Logo = styled.img`
  width: 250px; // Taille du logo augmentée
  height: auto;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.screen.medium}) {
    width: 200px;
    top: 20px;
    left: 50%; // Centre horizontalement le logo
    transform: translateX(-50%);
  }

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    width: 250px;
    top: 20px; // Positionne le logo à 20px du haut
    left: 50%; // Centre horizontalement le logo
    transform: translateX(-50%);
  }
`;

// Composant stylé pour l'image avec bords arrondis et ombre
const ScreenshotImage = styled.img`
  width: 300px;
  height: auto;
  margin-left: 15rem;
  border-radius: 20px; // Ajout de bords arrondis
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);

   @media (max-width: ${({ theme }) => theme.screen.medium}) {
    width: 200px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    margin: 0 auto; // Centre l'image horizontalement
    display: block; // Nécessaire pour que le margin auto fonctionne
  }

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    width: 135px;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    margin: 0 auto; // Centre l'image horizontalement
    display: block; // Nécessaire pour que le margin auto fonctionne
  }

  @media (max-width: 375px) and (max-height: 790px) {
    display: none; // Masque l'image si la largeur ≤ 375px et la hauteur ≤ 790px
  }
`;

const RedDot = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  background-color: #ff2521;
  border-radius: 50%;
  display: inline-block;
`;

const MainColumn = styled(Column)`
  isolation: isolate;
  margin-top: 2rem;
  align-content: flex-end;
  justify-content: flex-end;
  height: calc(100vh - 40px);

  @media (max-width: ${({ theme }) => theme.screen.medium}) {
    margin-top: 5rem;
    height: auto; // Permet à la hauteur de s'adapter au contenu
    align-content: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    justify-content: flex-start; // Aligne le contenu en haut
    padding-top: 2rem; // Ajoute un espace en haut si nécessaire
    margin-top: 15rem; // Supprime la marge supérieure
    padding-bottom: 5rem; // Ajoute de l'espace en bas
    
  }
`;

const BottomRow = styled(Row)`
  gap: 1.5rem;

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ButtonRow = styled(Row)`
  gap: 1.5rem;

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    padding: 0;
    flex-direction: column;
    align-items: stretch; // Étire les boutons pour qu'ils aient la même largeur
    width: 100%; // Assure que le conteneur prend toute la largeur
  }
`;

const LargeButton = styled(Button)`
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    width: 100%; // Les boutons prennent 100% de la largeur
    justify-content: center;
    flex: 1; // Assure que les boutons ont la même hauteur
  }
`;

const HeaderRow = styled(Row)`
  position: relative;
  top: 5rem;

  @media (max-width: ${({ theme }) => theme.screen.medium}) {
    top: -1rem;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    top: -2rem; // Remet le positionnement à zéro sur mobile
    margin-top: 0; // Supprime toute marge supérieure
  }
`;

const StyledPanel = styled(Panel)`
  position: relative;
  height: 100vh; // Prend toute la hauteur de la fenêtre
  width: 100vw; // Prend toute la largeur de la fenêtre
  overflow: hidden; // Pour éviter les barres de défilement si l'image dépasse légèrement

  @media (max-width: ${({ theme }) => theme.screen.small}) {
    height: 100vh; // Garde la hauteur pleine sur mobile aussi
    width: 100vw; // Garde la largeur pleine sur mobile
  }
`;


const StartExplore = () => {
  return (
    <StyledPanel firstPage>
      <BackgroundImage
  opacity="20%"
  image={require('../../assets/pexels-tobias-aeppli-1125272.jpg')}
  imgStyle={{ objectFit: 'cover', width: '100%', height: '100%', transform: 'scaleX(-1)' }}
/>

      {/* Logo agrandi et centré sur mobile */}
      <Logo src={logo} alt="Logo" />
      <MainColumn>
        <Column style={{ padding: '0' }}>
          <HeaderRow>
            {/* Conteneur pour le texte et le point rouge */}
            <Column style={{ padding: '0', maxWidth: '600px' }}>
              <Row>
                <RedDot />
                <h1 style={{ color: 'white' }}>
                  La chasse <HighlightedText>X'Plore découverte</HighlightedText>
                  <br />
                  disponible sur Rennes
                </h1>
              </Row>
            </Column>
            {/* Image de la capture d'écran agrandie avec bords arrondis et ombre */}
            <ScreenshotImage src={screenshot} alt="Capture d'écran" />
          </HeaderRow>
        </Column>

        <Column style={{ padding: '0' }}>
          <h2 style={{ lineHeight: '42px', color: 'white' }}>
            L'aventure t'attend, les récompenses aussi
          </h2>
          <BottomRow>
            <ButtonRow>
              <a
                href="https://play.google.com/store/apps/details?id=com.xploreapp.xplorepoc&hl=fr"
                target="_blank"
                rel="noreferrer"
              >
                <LargeButton primary>
                  <FontAwesomeIcon icon={faGooglePlay} size={'xl'} />
                  Disponible sur Play Store
                </LargeButton>
              </a>
              <a
                href="https://apps.apple.com/fr/app/xplore/id6736769148"
                target="_blank"
                rel="noreferrer"
              >
                <LargeButton primary>
                  <FontAwesomeIcon icon={faApple} size={'xl'} />
                  Disponible sur App Store
                </LargeButton>
              </a>
            </ButtonRow>
          </BottomRow>
        </Column>
      </MainColumn>
    </StyledPanel>
  );
};

export default StartExplore;
