// src/pages/LoginPage.js
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Panel from '../../components/Panel';
import { signOut } from "firebase/auth";

// Import Firebase Authentication et Firestore
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase/firebaseConfig"; // Import de la configuration Firebase
import { doc, getDoc } from "firebase/firestore"; // Import Firestore pour récupérer le document utilisateur

const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null); // Pour afficher les erreurs
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null); // Réinitialiser les erreurs avant une nouvelle tentative

        try {
            // Connexion avec Firebase Authentication
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Vérifier si l'utilisateur est admin dans la collection "user" de Firestore
            const userDocRef = doc(db, 'user', user.uid); // Chercher l'utilisateur par son ID (uid)
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists() && userDoc.data().admin === true) {
                // Si l'utilisateur est admin, redirection vers la page des chasses
                console.log("Login successful. Admin access granted.");
                navigate("/hunts");
            } else {
                // Si l'utilisateur n'est pas admin
                setError("Accès refusé : vous n'êtes pas administrateur.");
                await signOut(auth); 
            }
        } catch (error) {
            console.error("Error logging in:", error);
            setError("Email ou mot de passe incorrect.");
        }
    };

    return (
        <LoginPanel>
            <LoginForm onSubmit={handleLogin}>
                <h2>Connexion</h2>

                {/* Affiche un message d'erreur en cas de problème */}
                {error && <ErrorMessage>{error}</ErrorMessage>}

                <InputWrapper>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </InputWrapper>
                <InputWrapper>
                    <label htmlFor="password">Mot de passe</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </InputWrapper>

                {/* Commenter reCAPTCHA pour le moment */}
                {/* <ReCAPTCHA
                    sitekey='6Lf20VAqAAAAACJNV86S-MJg4Z3LngF8QEHP8cOl'
                    onChange={handleRecaptchaChange}
                /> */}

                <Button primary type="submit">Se connecter</Button>
            </LoginForm>
        </LoginPanel>
    );
};

// Styled Components

const LoginPanel = styled(Panel)`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
`;

const LoginForm = styled.form`
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    text-align: center;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    label {
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    input {
        padding: 0.75rem;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
    }
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 0.9rem;
    margin-bottom: 1rem;
`;

export default LoginPage;
