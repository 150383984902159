// src/components/ProtectedRoute.js
import React, { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig"; // Import de la configuration Firebase
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true); // Pour gérer l'état de chargement
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Vérifie l'état d'authentification de l'utilisateur
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Si l'utilisateur est connecté, on met à jour l'état
      } else {
        setUser(null); // Si l'utilisateur n'est pas connecté
      }
      setLoading(false); // Fin du chargement
    });

    // Nettoyer le listener lorsqu'on quitte le composant
    return () => unsubscribe();
  }, []);

  if (loading) {
    // Optionnel : Tu peux afficher un spinner ou autre pendant le chargement
    return <div>Loading...</div>;
  }

  if (!user) {
    // Si l'utilisateur n'est pas connecté, afficher le message
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', fontSize: '24px', color: 'red' }}>
        Connexion non valide
      </div>
    );
  }

  // Si l'utilisateur est connecté, on rend le contenu
  return children;
};

export default ProtectedRoute;
