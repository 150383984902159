import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore'; // Firestore imports
import { db } from '../../firebase/firebaseConfig'; // Import Firebase config
import whiteLogo from '../../assets/white.png'; // Import du logo blanc

const HuntRiddle = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [hunts, setHunts] = useState([]);
    const [loading, setLoading] = useState(true); // Ajout pour gestion du loading
    const navigate = useNavigate();

    // Fetch hunts from Firebase
    const fetchHunts = async () => {
        try {
            const huntCollection = collection(db, 'hunt');
            const huntSnapshot = await getDocs(huntCollection);
            const huntList = huntSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setHunts(huntList);
        } catch (error) {
            console.error("Erreur lors de la récupération des chasses :", error);
        } finally {
            setLoading(false); // Fin du loading
        }
    };

    useEffect(() => {
        fetchHunts();
    }, []);

    // Filter hunts based on search term
    const filteredHunts = hunts.filter(hunt =>
        hunt.name && hunt.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Container>
            <Sidebar>
                <Logo src={whiteLogo} alt="App Logo" />
                <SidebarItem 
                    onClick={() => navigate('/hunts')}
                    active={window.location.pathname.includes('/hunts')}
                >
                    Chasses
                </SidebarItem>
                <SidebarItem 
                    onClick={() => navigate('/riddles')}
                    active={window.location.pathname.includes('/riddles')}
                >
                    Enigmes
                </SidebarItem>
            </Sidebar>

            <Content>
                <HeaderWrapper>
                    <TitleWrapper>
                        <Title>Liste des chasses (énigmes)</Title>
                    </TitleWrapper>
                </HeaderWrapper>

                <ContentBox>
                    <SearchBar>
                        <FontAwesomeIcon icon={faSearch} />
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </SearchBar>

                    <ScrollableContent>
                        <HuntGrid>
                            {loading ? (
                                <LoadingMessage>Chargement des chasses...</LoadingMessage>
                            ) : (
                                filteredHunts.length > 0 ? (
                                    filteredHunts.map((hunt) => (
                                        <HuntBox 
                                            key={hunt.id} 
                                            onClick={() => navigate(`/riddles/${hunt.id}`)} 
                                            backgroundImage={hunt.picture} // URL de l'image
                                        >
                                            <span>{hunt.name}</span> {/* Nom de la chasse */}
                                        </HuntBox>
                                    ))
                                ) : (
                                    <NoResults>Aucun résultat trouvé</NoResults>
                                )
                            )}
                        </HuntGrid>
                    </ScrollableContent>
                </ContentBox>
            </Content>
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    display: flex;
    height: 100vh;
    background-color: #1f2b3a; /* Blue background color */
`;

const Sidebar = styled.div`
    width: 250px;
    background-color: #1f2b3a; /* Dark blue sidebar */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: white;
`;

const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-bottom: 2rem;
`;

const SidebarItem = styled.div`
    font-size: 1.25rem;
    margin: 1rem 0;
    cursor: pointer;
    color: ${({ active }) => (active ? '#19a544' : 'white')}; /* Green when active, white otherwise */
    &:hover {
        background-color: #34495e;
        width: 100%;
        text-align: center;
        padding: 0.5rem 0;
        color: #19a544; /* Green hover */
    }
`;

const Content = styled.div`
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: flex-start; /* Align items to the left */
    margin-bottom: 2rem;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 800;
    color: white; /* Title in white */
`;

const ContentBox = styled.div`
    background-color: white;
    flex: 1;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Prevent scrolling for the entire box */
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    input {
        margin-left: 1rem;
        padding: 0.5rem;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
`;

const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto; /* Scrollable content */
`;

const HuntGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 1.5rem; 
    margin-top: 1rem;
`;

const HuntBox = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecf0f1;
    background-image: ${({ backgroundImage }) => backgroundImage ? `url(${backgroundImage})` : 'none'};
    background-size: cover;
    background-position: center;
    height: 150px;
    border: none; /* Suppression de la bordure */
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;

    /* Dark overlay */
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5); /* Darken the background image */
        z-index: 1; /* Overlay above the image */
    }

    /* Zoom effect on the text when hovering over the box */
    &:hover span {
        transform: scale(1.1); /* Zoom the text on hover */
    }

    span {
        position: relative;
        z-index: 2;
        color: white;
        font-size: 1.5rem;
        font-weight: bold; /* Render the name in bold */
        text-align: center;
        transition: transform 0.3s ease-in-out; /* Smooth zoom transition */
    }
`;

const NoResults = styled.div`
    grid-column: span 3;
    text-align: center;
    font-size: 1.25rem;
    color: red;
`;

const LoadingMessage = styled.div`
    grid-column: span 3;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
`;

export default HuntRiddle;
