import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Home from "./parts/home/Home";
import Team from "./parts/team/Team";
import HowToPlay from "./parts/how_to_play/HowToPlay";
import Navbar from "./parts/navbar/Navbar";
import DarkTheme from './theme/Theme';
import { ThemeProvider } from 'styled-components';
import Footer from "./parts/footer/Footer";
import Litepaper from "./parts/litepaper/Litepaper";
import NewsLetter from "./parts/newsletter/Newsletter";
import CGUCGV from "./parts/cgu_cgv/CGUCGV"; 
import PrivacyPolicy from "./parts/privacy_policy/PrivacyPolicy"; 
import DeleteAccount from "./parts/delete_account/deleteAccount"; 
import LoginPage from "./parts/login/LoginPage"; 
import HuntDisplay from "./parts/hunts/HuntsDisplay"; 
import HuntForm from "./parts/hunts/HuntForm"; 
import HuntRiddle from "./parts/hunts/HuntRiddle"; 
import Riddles from "./parts/hunts/Riddles";
import RiddleForm from "./parts/hunts/RiddleForm"; 
import ProtectedRoute from "./components/ProtectedRoute";
import EditRiddle from "./parts/hunts/EditRiddle";
import EditHunt from "./parts/hunts/EditHunt";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}


function App() {

    const path = window.location.pathname
    const [currentPage, setCurrentPage] = useState(path);

    useEffect(() => {
        setCurrentPage(path)
    }, [])

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Navbar currentPage={currentPage} setCurrentPage={setCurrentPage} />
            <Routes>
                <Route exact path="/" element={<Home setCurrentPage={setCurrentPage} />} />
                <Route path="/how-to-play" element={<HowToPlay />} />
                <Route path="/litepaper" element={<Litepaper />} />
                <Route path="/team" element={<Team />} />
                <Route path="/cgu-cgv" element={<CGUCGV />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/hunts" element={<ProtectedRoute><HuntDisplay /></ProtectedRoute>} /> 
                <Route path="/hunts/new" element={<ProtectedRoute><HuntForm /></ProtectedRoute>} />
                <Route path="/hunts/:huntId/edit" element={<ProtectedRoute><EditHunt /></ProtectedRoute>} />
                <Route path="/riddles" element={<ProtectedRoute><HuntRiddle /></ProtectedRoute>} /> 
                <Route path="/riddles/:huntId" element={<ProtectedRoute><Riddles /></ProtectedRoute>} />
                <Route path="/riddles/:huntId/newriddle" element={<ProtectedRoute><RiddleForm /></ProtectedRoute>} />
                <Route path="/riddles/:huntId/:riddleId/edit" element={<ProtectedRoute><EditRiddle /></ProtectedRoute>} />
                
            </Routes>
            <NewsLetter />
            <Footer currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </BrowserRouter>
    );
}

export default App;
