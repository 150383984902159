import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, Timestamp, updateDoc } from 'firebase/firestore'; // Firestore imports
import { db } from '../../firebase/firebaseConfig'; // Import Firebase config
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import des styles de react-toastify
import whiteLogo from '../../assets/white.png'; // Import du logo blanc




const HuntForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        code: '',
        deployment: false,
        location: '',
        nbPlayer: 10,
        limited: true,
        nbRiddle: 0,
        price: 15,
        reward: 1,
        picture: null,
        nbReg: 0, // Nombre de joueurs inscrits (par défaut 0)
    });

    const [loading, setLoading] = useState(false); // État pour gérer le chargement
    const navigate = useNavigate();  // Initialiser useNavigate
    const [codeDigits, setCodeDigits] = useState(Array(6).fill(''));    
    const [imagePreview, setImagePreview] = useState(null); // Pour stocker l'URL de prévisualisation

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        // Vérifier si le champ est de type "number" et caster en nombre
        const newValue = type === 'number' ? Number(value) : value;
    
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : newValue,
        });
    };

    const handleCodeChange = (e, index) => {
        const { value } = e.target;
        
        // Limiter la valeur à un seul chiffre (0-9)
        if (/^\d$/.test(value) || value === '') {
            const newCodeDigits = [...codeDigits];
            newCodeDigits[index] = value;
            setCodeDigits(newCodeDigits);
        }
    };
    
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        // Mettre à jour le formulaire avec l'image sélectionnée
        setFormData({
            ...formData,
            picture: file,
        });
    
        // Lire l'image pour la prévisualisation
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result); // Mettre à jour l'aperçu avec le fichier lu
        };
        if (file) {
            reader.readAsDataURL(file); // Convertir le fichier en URL de données
        } else {
            setImagePreview(null); // Si aucun fichier n'est sélectionné, enlever l'aperçu
        }
    };
    

    // Fonction de soumission du formulaire
    const handleSubmit = async (e) => {

        if (!formData.name.trim()) {
            toast.error("Le nom est obligatoire.", { position: "top-right", autoClose: 3000 });
            return; // Arrêter la soumission si la validation échoue
        }
    
        // Vérifier si le nombre de joueurs est inférieur à 10
        if (formData.nbPlayer < 10) {
            toast.error("Le nombre de joueurs doit être au moins 10.", { position: "top-right", autoClose: 3000 });
            return; // Arrêter la soumission si la validation échoue
        }
        toast.info("Création de la chasse...");
        e.preventDefault();
    
        // Activer le loading
        setLoading(true);

        const code = codeDigits.join('');      
        
    
        // Calculer les dates automatiquement
        const startingDate = Timestamp.fromDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
        const endingDate = Timestamp.fromDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));
    
        // Référence à Firebase Storage
        const storage = getStorage();
        let imageUrl = '';
    
        // Vérifier si une image est présente
        if (formData.picture) {
            const storageRef = ref(storage, `hunt/${formData.picture.name}`); // Chemin où l'image sera stockée
            try {
                // Uploader l'image
                const snapshot = await uploadBytes(storageRef, formData.picture);
                // Obtenir l'URL du fichier stocké
                imageUrl = await getDownloadURL(snapshot.ref);
            } catch (error) {
                console.error('Erreur lors du téléchargement de l\'image :', error);
                toast.error('Erreur lors du téléchargement de l\'image.', { position: "top-right", autoClose: 3000 });
                setLoading(false);
                return;
            }
        }
    
        const huntData = {
            deployment :formData.deployment,
            name: formData.name,
            description: formData.description,
            location: formData.location,
            code:code,
            nbPlayer: formData.nbPlayer,
            limited: formData.limited,
            nbRiddle: formData.nbRiddle,
            price: formData.price,
            reward: formData.reward,
            picture: imageUrl || '', // Utiliser l'URL de l'image ou une chaîne vide si pas d'image
            nbReg: 0, // Initialisé à 0
            startingDate,
            endingDate,
            ranking: ['THEGOAT'], // Premier utilisateur au rang "THEGOAT"
        };
    
        try {
            // Ajouter un nouveau document à la collection 'hunt'
            const docRef = await addDoc(collection(db, 'hunt'), huntData);
    
            // Utiliser l'ID auto-généré pour mettre à jour la chasse
            const newHunt = {
                ...huntData,
                id: docRef.id,  // Ajouter l'ID généré par Firestore dans les données de la chasse
            };
            await updateDoc(docRef, newHunt); 
            // Afficher une notification de succès
            toast.success('Chasse créée avec succès !', { position: "top-right", autoClose: 3000 });
    
            console.log('Chasse créée avec succès', newHunt);
    
            // Redirection vers la liste des chasses après un petit délai
            setTimeout(() => {
                setLoading(false);
                navigate('/hunts');
            }, 3000);
        } catch (error) {
            // Afficher une notification d'erreur
            toast.error('Erreur lors de la création de la chasse.', { position: "top-right", autoClose: 3000 });
            console.error('Erreur lors de la création de la chasse :', error);
            setLoading(false); // Désactiver le loading en cas d'erreur
        }
    };

    
    
    

    return (
        <Container>
            {/* Notifications */}
            <ToastContainer />
            
            <Sidebar>
                <Logo src={whiteLogo} alt="App Logo" />
                <SidebarItem onClick={() => navigate('/hunts')} active={window.location.pathname.includes('/hunts')}>
                    Chasses
                </SidebarItem>
                <SidebarItem onClick={() => navigate('/riddles')} active={window.location.pathname.includes('/riddles')}>
                    Enigmes
                </SidebarItem>
            </Sidebar>

            <Content>
                <Header>
                    <Title>Créer une nouvelle chasse</Title>
                    <SubmitButton onClick={handleSubmit} disabled={loading}>
                        {loading ? 'Création...' : 'Créer'}
                    </SubmitButton> {/* Bouton à droite du titre */}
                </Header>
                <ContentBox>
                    <Form onSubmit={handleSubmit}>
                        <FormWrapper>
                            <LeftColumn>
                                <InputWrapper>
                                    <label>Nom :</label>
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Description :</label>
                                    <textarea
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Code :</label>
                                        <CodeInputWrapper>
                                            {codeDigits.map((digit, index) => (
                                            <CodeInput
                                                key={index}
                                                type="text"
                                                maxLength="1"
                                                value={digit}
                                                onChange={(e) => handleCodeChange(e, index)}
                                            />
                                                ))}
                                        </CodeInputWrapper>
                                </InputWrapper>


                                <InputWrapper>
                                    <label>Lieu :</label>
                                    <input
                                        type="text"
                                        name="location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Nombre de Joueurs :</label>
                                    <input
                                        type="number"
                                        name="nbPlayer"
                                        value={formData.nbPlayer}
                                        min="10"
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <SwitchWrapper>
                                    <label>Limitée :</label>
                                    <Switch>
                                        <input
                                            type="checkbox"
                                            name="limited"
                                            checked={formData.limited}
                                            onChange={handleInputChange}
                                            
                                        />
                                        <Slider />
                                    </Switch>
                                </SwitchWrapper>

                                <InputWrapper>
                                    <label>Prix :</label>
                                    <input
                                        type="number"
                                        name="price"
                                        value={formData.price}
                                        min="1"
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Récompense :</label>
                                    <input
                                        type="number"
                                        name="reward"
                                        value={formData.reward}
                                        min="1"
                                        onChange={handleInputChange}
                                        required
                                    />
                                </InputWrapper>

                                <InputWrapper>
                                    <label>Upload image :</label>
                                    <input type="file" onChange={handleFileChange} />
                                    {/* Affichage de l'aperçu de l'image si disponible */}
                                    {imagePreview && <ImagePreview src={imagePreview} alt="Aperçu de l'image" />}
                                </InputWrapper>
                            </LeftColumn>

                            <RightColumn>
                                <SwitchWrapper>
                                    <label>Deployed :</label>
                                    <Switch>
                                        <input
                                            type="checkbox"
                                            name="deployment"
                                            checked={formData.deployment}
                                            onChange={handleInputChange}
                                        />
                                        <Slider />
                                    </Switch>
                                </SwitchWrapper>
                            </RightColumn>
                        </FormWrapper>
                    </Form>
                </ContentBox>
            </Content>
        </Container>
    );
};






// Styled Components
const Container = styled.div`
    display: flex;
    height: 100vh;
    background-color: #1f2b3a; /* Fond bleu sombre */
`;

const Sidebar = styled.div`
    width: 250px;
    background-color: #1f2b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: white;
`;

const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-bottom: 2rem;
`;

const SidebarItem = styled.div`
    font-size: 1.25rem;
    margin: 1rem 0;
    cursor: pointer;
    color: ${({ active }) => (active ? '#19a544' : 'white')}; /* Couleur verte si active */
    &:hover {
        background-color: #34495e;
        width: 100%;
        text-align: center;
        padding: 0.5rem 0;
        color: #19a544;
    }
`;

const Content = styled.div`
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between; /* Aligner le bouton à droite */
    align-items: center;
    margin-bottom: 2rem;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 800;
    color: white; /* Couleur pour contraster avec le fond */
`;

const ContentBox = styled.div`
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    flex: 1;
    overflow: hidden; /* Empêcher le scroll ici */
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    overflow-y: auto; /* Scroll uniquement pour les attributs */
    gap: 2rem;
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 70%;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 25%;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }
    input, textarea {
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        width: 100%;
    }
    textarea {
        resize: vertical;
    }
`;

const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`;

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;

    &::before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked + & {
        background-color: #4CAF50;
    }

    input:checked + &::before {
        transform: translateX(26px);
    }
`;

const SubmitButton = styled.button`
    padding: 1rem 3rem; /* Augmenter la taille du bouton */
    background-color: #19a544;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem; /* Augmenter la taille de la police */
    cursor: pointer;
    &:hover {
        background-color: #16a085;
    }
`;

const CodeInputWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

const CodeInput = styled.input`
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
`;

const ImagePreview = styled.img`
    margin-top: 10px;
    width: 200px; /* Largeur de l'aperçu de l'image */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;





export default HuntForm;
