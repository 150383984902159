import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, addDoc, collection, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL,uploadString } from 'firebase/storage';
import { db } from '../../firebase/firebaseConfig';
import { ToastContainer, toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css'; // Import des styles de react-toastify
import whiteLogo from '../../assets/white.png';
import QRCode from 'qrcode';
import CryptoJS from 'crypto-js';

const RiddleForm = () => {
    const [formData, setFormData] = useState({
        audio: '',
        description: '',
        pictureMain: '',
        pictureSecondary: [],
    });
    const [huntName, setHuntName] = useState("");
    const [nbRiddle, setNbRiddle] = useState(0); // Stocke le nombre d'énigmes existantes
    const [imagePreview, setImagePreview] = useState(null); // Pour stocker l'URL de prévisualisation de l'image principale
    const [audioPreview, setAudioPreview] = useState(null); // Pour stocker l'URL de prévisualisation de l'audio
    const { huntId } = useParams(); // Récupérer l'ID de la chasse
    const navigate = useNavigate();
    const storage = getStorage(); // Firebase Storage instance

    // Récupérer les détails de la chasse (nom et nombre d'énigmes)
    useEffect(() => {
        const fetchHuntDetails = async () => {
            try {
                const huntDocRef = doc(db, 'hunt', huntId);
                const huntDoc = await getDoc(huntDocRef);

                if (huntDoc.exists()) {
                    const huntData = huntDoc.data();
                    setHuntName(huntData.name);
                    setNbRiddle(huntData.nbRiddle); // Nombre actuel d'énigmes
                } else {
                    console.log('Aucune chasse trouvée');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de la chasse:', error);
                toast.error('Erreur lors de la récupération des détails de la chasse.');
            }
        };

        fetchHuntDetails();
    }, [huntId]);

    // Gestion de l'upload des fichiers
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'pictureSecondary') {
            if (files.length + formData.pictureSecondary.length > 6) {
                toast.warn("Vous ne pouvez ajouter que 6 images secondaires au maximum.");
                return;
            }
            setFormData({
                ...formData,
                pictureSecondary: [...formData.pictureSecondary, ...files],
            });
        } else if (name === 'pictureMain') {
            // Prévisualisation de l'image principale
            const file = files[0];
            setFormData({
                ...formData,
                pictureMain: file,
            });
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Prévisualisation de l'image principale
            };
            if (file) {
                reader.readAsDataURL(file);
            }
        } else if (name === 'audio') {
            // Prévisualisation de l'audio
            const audioFile = files[0];
            setFormData({
                ...formData,
                audio: audioFile,
            });
            setAudioPreview(URL.createObjectURL(audioFile)); // Création de l'URL pour l'aperçu audio
        }
    };

    // Gestion de la modification de la description
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Fonction pour uploader un fichier dans Firebase Storage
    const uploadFile = async (file, path) => {
        const fileRef = ref(storage, path);
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        return downloadURL;
    };

    // Fonction pour générer une chaîne aléatoire de 30 caractères
    const generateRandomString = (length = 32) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    const generateQRCode = async (url) => {
        try {
            const qrCodeDataUrl = await QRCode.toDataURL(url);
            return qrCodeDataUrl;
        } catch (err) {
            console.error('Erreur lors de la génération du QR code:', err);
            return null;
        }
    };

    const generateMd5Hash = (url) => {
        return CryptoJS.MD5(url).toString();
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        toast.info("Création de l'énigme en cours...");

        try {
            // 1. Générer la chaîne aléatoire et l'URL
            const normalizedHuntName = normalizeHuntName(huntName);
            const randomKey = CryptoJS.enc.Hex.parse(CryptoJS.lib.WordArray.random(16).toString());
            const iv = CryptoJS.lib.WordArray.random(16);
            const solutionUrl = `https://www.xplore-app.com/${randomKey}/${iv}`;

            // 2. Générer le QR code à partir de l'URL
            const qrCodeDataUrl = await generateQRCode(solutionUrl);

            if (!isValidCoordinate(formData.latitude) || !isValidCoordinate(formData.longitude)) {
                toast.error("Veuillez entrer des coordonnées valides.");
                return;
            }

            
            if (formData.latitude && formData.longitude) {
                // Si la latitude et la longitude ne sont pas vides, combiner et chiffrer
                const coordinates = `${formData.latitude}, ${formData.longitude}`.trim(); 
                var encryptedCoordinates = CryptoJS.AES.encrypt(coordinates, randomKey, { iv: iv });
                
                
            }

            if (!qrCodeDataUrl) {
                toast.error('Erreur lors de la génération du QR code.');
                return;
            }

            // 3. Stocker le QR code dans Firebase Storage
            const qrCodePath = `riddle/${normalizedHuntName}/qrcodes/enigme${nbRiddle + 1}`;
            const qrCodeRef = ref(storage, qrCodePath);
            await uploadString(qrCodeRef, qrCodeDataUrl, 'data_url');
            

            // 4. Générer le hash MD5 de l'URL
            const md5Hash = generateMd5Hash(solutionUrl);

            // 5. Uploader l'image principale
            const pictureMainUrl = await uploadFile(formData.pictureMain, `riddle/${normalizedHuntName}/pictures/${formData.pictureMain.name}`);

            // 6. Uploader les images secondaires
            const pictureSecondaryUrls = await Promise.all(
                formData.pictureSecondary.map((file, index) =>
                    uploadFile(file, `riddle/${normalizedHuntName}/pictures/${file.name}`)
                )
            );            
            

            // 7. Créer un nouveau riddle dans Firestore
            const newRiddleData = {
                huntId,
                number: nbRiddle + 1,
                description: formData.description,
                solution: md5Hash, // Stocker le hash MD5 comme solution
                picture: pictureMainUrl, // Stocker l'image principale
                picturesRiddle: pictureSecondaryUrls, // Stocker les images secondaires               
                ranking: ['THEGOAT'], 
                coordinates: encryptedCoordinates.toString(),
                audio: audioPreview ? await uploadFile(formData.audio, `riddle/${normalizedHuntName}/audios/enigme${nbRiddle + 1}`) : "",
                timestamp: Timestamp.now(),
            };

            const riddleRef = await addDoc(collection(db, 'riddle'), newRiddleData);
            const newRiddle = {
                ...newRiddleData,
                id: riddleRef.id,  // Ajouter l'ID généré par Firestore dans les données de la chasse
            };

            // Mettre à jour la chasse pour incrémenter le nombre d'énigmes
            const huntRef = doc(db, 'hunt', huntId);
            await updateDoc(riddleRef, newRiddle);
            await updateDoc(huntRef, { nbRiddle: nbRiddle + 1 });

            toast.success('Énigme créée avec succès !');
            navigate(`/riddles/${huntId}`);
        } catch (error) {
            console.error('Erreur lors de la création de l\'énigme:', error);
            toast.error('Erreur lors de la création de l\'énigme.');
        }
    };

    const normalizeHuntName = (huntName) => {
        // Utilisation d'une expression régulière pour garder uniquement les lettres et chiffres
        return huntName.replace(/[^a-zA-Z0-9]/g, '');
    };

    const isValidCoordinate = (value) => {
        const coordinatePattern = /^-?\d+(\.\d+)?$/; // Autorise les nombres avec ou sans décimale, avec un signe négatif optionnel
        return coordinatePattern.test(value);
    };


    return (
        <Container>
            <ToastContainer /> {/* Container pour afficher les notifications */}
            <Sidebar>
                <Logo src={whiteLogo} alt="App Logo" />
                <SidebarItem onClick={() => navigate('/hunts')} active={window.location.pathname.includes('/hunts')}>
                    Chasses
                </SidebarItem>
                <SidebarItem onClick={() => navigate('/riddles')} active={window.location.pathname.includes('/riddles')}>
                    Enigmes
                </SidebarItem>
            </Sidebar>

            <Content>
                <HeaderWrapper>
                    <Title>Créer une nouvelle énigme - {huntName}</Title>
                    <SubmitButton onClick={handleSubmit}>Créer</SubmitButton>
                </HeaderWrapper>

                <ContentBox>
                    <Form onSubmit={handleSubmit}>
                        <InputWrapper>
                            <label>Audio :</label>
                            <input
                                type="file"
                                name="audio"
                                accept="audio/*"
                                onChange={handleFileChange}
                                required
                            />
                            {audioPreview && (
                                <AudioPlayer controls>
                                    <source src={audioPreview} type="audio/mpeg" />
                                    Votre navigateur ne supporte pas l'élément audio.
                                </AudioPlayer>
                            )}
                        </InputWrapper>

                        <InputWrapper>
                            <label>Description :</label>
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <label>Image Principale :</label>
                            <input
                                type="file"
                                name="pictureMain"
                                accept="image/*"
                                onChange={handleFileChange}
                                required
                            />
                            {imagePreview && (
                                <ImagePreview src={imagePreview} alt="Aperçu de l'image principale" />
                            )}
                        </InputWrapper>

                        <InputWrapper>
                            <label>Images Secondaires (jusqu'à 6) :</label>
                            <input
                                type="file"
                                name="pictureSecondary"
                                accept="image/*"
                                multiple
                                onChange={handleFileChange}
                            />
                            {formData.pictureSecondary.length > 0 && (
                                <ImagePreviewWrapper>
                                    {Array.from(formData.pictureSecondary).map((file, index) => (
                                        <img key={index} src={URL.createObjectURL(file)} alt={`secondary-${index}`} />
                                    ))}
                                </ImagePreviewWrapper>
                            )}
                        </InputWrapper>
                        <FlexContainer>
                        <InputWrapper>
                            <label>Latitude :</label>
                                <input
                                    type="number"
                                    name="latitude"
                                    placeholder="Exemple: 48.13065361205093"
                                    value={formData.latitude}
                                    onChange={handleInputChange}
                                    step="any"
                                    required                                    
                                />
                        </InputWrapper>

                        <InputWrapper>
                            <label>Longitude :</label>
                            <input
                                type="number"
                                name="longitude"
                                placeholder="Exemple: -1.6958905470621606"
                                value={formData.longitude}
                                onChange={handleInputChange}
                                step="any"
                                required
                            />
                        </InputWrapper>
                        </FlexContainer>
                    </Form>
                </ContentBox>
            </Content>
        </Container>
    );
};


// Styled Components

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Espacement entre les champs */
    margin-bottom: 1.5rem; /* Espace entre les champs et les éléments suivants */
`;




const Container = styled.div`
    display: flex;
    height: 100vh;
    background-color: #1f2b3a; /* Fond bleu sombre */
`;

const Sidebar = styled.div`
    width: 250px;
    background-color: #1f2b3a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    color: white;
`;

const Logo = styled.img`
    width: 150px;
    height: auto;
    margin-bottom: 2rem;
`;

const SidebarItem = styled.div`
    font-size: 1.25rem;
    margin: 1rem 0;
    cursor: pointer;
    color: ${({ active }) => (active ? '#19a544' : 'white')}; /* Couleur verte si active */
    &:hover {
        background-color: #34495e;
        width: 100%;
        text-align: center;
        padding: 0.5rem 0;
        color: #19a544;
    }
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between; /* Aligner le bouton à droite */
    align-items: center;
    margin-bottom: 2rem;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 800;
    color: white; /* Couleur blanche pour contraster avec le fond */
`;

const ContentBox = styled.div`
    background-color: white;
    flex: 1;
    padding: 2rem;
    border-radius: 20px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 100%; /* Assurer que la boîte ne dépasse pas */
    overflow-y: auto; /* Permettre le scroll si nécessaire */
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1; /* Chaque InputWrapper prendra une largeur égale */
    label {
        margin-bottom: 0.5rem;
        font-weight: bold;
    }
    input {
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 1rem;
        width: 100%; /* S'assurer que l'input prend toute la largeur disponible */
    }
`;

const ImagePreviewWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 1rem;

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 5px;
    }
`;

const SubmitButton = styled.button`
    padding: 1rem 3rem; /* Augmenter la taille du bouton */
    background-color: #19a544;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.5rem; /* Augmenter la taille de la police */
    cursor: pointer;
    &:hover {
        background-color: #16a085;
    }
`;

const ImagePreview = styled.img`
    margin-top: 10px;
    width: 200px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const AudioPlayer = styled.audio`
    margin-top: 10px;
    width: 100%;
`;



export default RiddleForm;
