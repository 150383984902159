// src/components/Navbar.js
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";

const NavbarWrapper = styled.div`
    padding: 2.5rem 3.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* Alignement à gauche */
    align-items: center;
    z-index: 10;
    background-color: ${(props) => props.backgroundColor};
    transition: all 0.2s ease-out;

    @media (max-width: ${({ theme }) => theme.screen.large}) {
        gap: 5rem;
    }

    @media (max-width: ${({ theme }) => theme.screen.medium}) {
        padding: 1rem;
        flex-direction: column;
        gap: 1rem;
        height: auto;
        transition: all 0.2s ease-in;

        img {
            display: ${(props) => (props.scroll ? "none" : "visible")};
        }
    }
`;

const NavbarLogo = styled.img`
    width: 300px; /* Taille du logo */
    height: auto;
    object-fit: contain;
    &:hover {
        cursor: pointer;
    }
`;

const Navbar = ({ currentPage, setCurrentPage }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [lightMode, setLightMode] = useState(true);
    const [navbarLogo, setNavbarLogo] = useState(null);

    useEffect(() => {
        switch (currentPage) {
            case "/":
            case "/how-to-play":
                setLightMode(false);
                setNavbarLogo(require("../../assets/white.png"));
                break;
            case "/litepaper":
            case "/team":
                setLightMode(true);
                setNavbarLogo(require("../../assets/colored.png"));
                break;
            default:
                setLightMode(true);
                setNavbarLogo(require("../../assets/colored.png"));
                break;
        }
    }, [currentPage]);

    // Pages où la navbar ne doit pas apparaître
    const hideNavbar =
        ["/login","/"].includes(location.pathname) ||
        location.pathname.startsWith("/riddles") ||
        location.pathname.startsWith("/hunts");

    if (hideNavbar) return null;

    return (
        <NavbarWrapper backgroundColor={lightMode ? "#fff" : "none"}>
            {navbarLogo !== null && (
                <NavbarLogo
                    src={navbarLogo}
                    alt="logo_navbar"
                    onClick={() => {
                        setCurrentPage("/");
                        navigate("/");
                    }}
                />
            )}
        </NavbarWrapper>
    );
};

export default Navbar;
