// src/firebase/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Ta configuration Firebase ici (copie-la depuis ton projet Firebase)
const firebaseConfig = {
  apiKey: "AIzaSyBNqt3pTLNjrD1ByKaBJE1u4IpJS_J_TsI",
  authDomain: "xplore-69bba.firebaseapp.com",
  projectId: "xplore-69bba",
  storageBucket: "xplore-69bba.appspot.com",
  messagingSenderId: "656693762876",
  appId: "1:656693762876:web:32f85bc64e72297becdc39",
  measurementId: 'G-J4XFRBDESX',
};

// Initialise Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Initialise Firebase Authentication et exporte-la pour l'utiliser ailleurs
export const auth = getAuth(app);
export { db };


